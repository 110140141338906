<template>
  <section class="page-other-transaction-add">
    <page-header
      :back-to="{ name: 'other-transaction' }"
      title="Tambah Data Transaksi Lainnya"
      class="mb-32"
    />
    <bg-grid>
      <bg-grid-item :col="12">
        <bg-field
          class="mb-16"
          label="Jenis Data Transaksi"
          description="Pengeluaran Properti"
        />

        <bg-divider />
      </bg-grid-item>

      <bg-grid-item :col="6">
        <bg-field label="Tipe Pengajuan Cash-Out">
          <bg-skeleton
            v-if="isLoading.page"
            width="100%"
            height="48px"
            data-testid="loader"
          />
          <bg-select
            v-else
            placeholder="Pilih tipe pengajuan cash-out"
            v-model="input.cash_out_id"
            :options="options.cash_out"
          />
        </bg-field>
        <bg-field label="Nama Properti">
          <bg-skeleton v-if="isLoading.page" width="100%" height="48px" />

          <bg-search-bar
            v-else
            placeholder="Pilih properti"
            v-model="display.property"
            :suggestions="options.propertyName"
            :loading="isLoading.propertyList"
            @input="onSearchInputProperty"
            @click-suggestion="onSelectProperty"
          />
        </bg-field>

        <bg-grid>
          <bg-grid-item :col="6" class="mb-0">
            <bg-field class="mb-0" label="Kota" :description="display.city" />
          </bg-grid-item>
          <bg-grid-item :col="6" class="mb-0">
            <bg-field
              class="mb-0"
              label="Sisa Kontrak Kerja Sama"
              :description="display.remainingContract"
            />
          </bg-grid-item>
        </bg-grid>
      </bg-grid-item>
    </bg-grid>

    <bg-skeleton v-if="isLoading.page" width="100%" height="329px" />
    <other-transaction-expenses
      v-else
      class="mb-24"
      :options="options"
      @is-all-expenses-filled="expensesValidation"
      @get-expenses="getExpenses"
    />

    <bg-grid>
      <bg-grid-item :col="6" class="mb-0">
        <bg-field
          class="mb-16"
          label="Total Pengeluaran"
          :description="formattedTotalExpense"
        />

        <bg-field
          class="flex mb-16"
          label="Lampiran"
          :message="
            attachment.isError ? attachment.errMessage : attachment.description
          "
          :error="attachment.isError"
        >
          <bg-skeleton v-if="isLoading.page" width="250px" height="250px" />
          <bg-uploader
            v-else
            :accept="attachment.type"
            :progress="attachment.progress"
            :is-error="attachment.isError"
            :message="attachment.message"
            :box-height="attachment.isEmpty ? 250 : 60"
            :box-width="250"
            :is-progress-delete="attachment.isDeleting"
            :is-success-delete="attachment.deleted"
            @upload="handleChangeFile"
            @delete="handleDeleteAttachment"
          />
        </bg-field>

        <bg-field class="mb-16" label="No. Invoice Biaya" message="Opsional">
          <bg-skeleton v-if="isLoading.page" width="100%" height="48px" />
          <bg-input
            v-else
            class="mb-8"
            placeholder="Masukkan invoice biaya"
            maxlength="30"
            data-testid="cost-invoice-number"
            v-model="input.invoice_number"
          />
        </bg-field>

        <bg-field
          class="mb-16"
          label="Tujuan Transfer Pengeluaran"
          :message="input.cash_out_id === 1 ? 'Opsional' : ''"
        >
          <bg-skeleton v-if="isLoading.page" width="100%" height="48px" />
          <bg-select
            v-else
            v-model="input.vendor_id"
            placeholder="Pilih tujuan transfer pengeluaran"
            searchable
            :search-placeholder="'Cari tujuan transfer'"
            :options="options.vendors"
            data-testid="vendor"
          />
        </bg-field>
      </bg-grid-item>
    </bg-grid>

    <bg-grid>
      <bg-grid-item class="mb-24" :col="3">
        <bg-field
          label="Nama Bank"
          data-testid="bank-name"
          :description="display.bank_name"
        />
      </bg-grid-item>
      <bg-grid-item class="mb-24" :col="3">
        <bg-field
          label="Nomor Rekening"
          data-testid="account-number"
          :description="display.account_number"
        />
      </bg-grid-item>
      <bg-grid-item class="mb-24" :col="3">
        <bg-field
          label="Nama Pemilik Rekening"
          data-testid="account-name"
          :description="display.account_name"
        />
      </bg-grid-item>
    </bg-grid>

    <bg-button
      variant="primary"
      :disabled="!isAllFieldFilled"
      data-testid="add-expenditure"
      @click="showConfirmationModal = true"
    >
      Tambah Data
    </bg-button>

    <bg-modal
      v-model="showConfirmationModal"
      title="Yakin ingin tambahkan data ini?"
      description="Data yang ditambahkan akan dilanjutkan ke tahap konfirmasi."
      desktop-size="sm"
      :close-on-click-backdrop="false"
      :closable="false"
    >
      <template #footer>
        <div class="flex justify-end">
          <bg-button
            class="mr-16"
            size="lg"
            :loading="isLoading.postData"
            @click="showConfirmationModal = false"
          >
            Batal
          </bg-button>
          <bg-button
            variant="primary"
            size="lg"
            :loading="isLoading.postData"
            @click="fetchPostExpenditure"
          >
            Tambah
          </bg-button>
        </div>
      </template>
    </bg-modal>
  </section>
</template>

<script>
import {
  BgGrid,
  BgGridItem,
  BgField,
  BgSelect,
  BgDivider,
  BgSkeleton,
  BgSearchBar,
  BgInput,
  BgUploader,
  BgButton,
  BgModal,
} from 'bangul-vue';

import { endpoints } from '@admin/api/endpoints/other-transaction-expenditure.js';
import expenditureMixins from '../mixins/expenditureMixins.js';

export default {
  name: 'OwnerExpenditureAdd',

  components: {
    BgGrid,
    BgGridItem,
    BgField,
    BgSelect,
    BgDivider,
    BgSkeleton,
    BgSearchBar,
    BgInput,
    BgUploader,
    BgButton,
    BgModal,
    PageHeader: () => import('@admin_molecules/PageHeader'),
    OtherTransactionExpenses: () =>
      import('../_components/OtherTransactionExpenses'),
  },

  mixins: [expenditureMixins],

  async created() {
    this.isLoading.page = true;
    this.fetchGetPropertyExpensesOptions();
    this.fetchGetPropertyExpensesVendors();
    await this.fetchGetProperties();
    this.isLoading.page = false;
  },

  methods: {
    async fetchPostExpenditure() {
      this.isLoading.postData = true;
      const response = await endpoints.postExpenditure(this.input);

      if (response) {
        this.$router.push({ name: 'other-transaction' });
        this.$toast.show('Data berhasil ditambah');
      }
      this.isLoading.postData = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-other-transaction-add {
  padding: 0 16px;
}
::v-deep .bg-c-uploader {
  > div > p {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    font-family: 'Lato', sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bg-c-progress-bar__indicator {
    background-color: #a4237e;
  }

  &__preview-content-action-reupload,
  &__preview-content-action-cancel {
    display: none;
  }
}
</style>
